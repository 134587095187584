import React from "react";

import {
  Button,
  Card,
  Dropdown,
  Toast,
  useUserPreferences,
  setUserPreferencesState,
  LOCALES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const mainTabDetails = ["Reading", "Writing", "Speaking"];

// @ts-ignore
import i18n from "@projectdiction/translations";
import PDSettingsFooter from "./PDSettingsFooter";
import PDSettingOptionCard from "./PDSettingOptionCard";

function PDSettingsPage() {
  const { t } = useTranslation();

  const preferences = useUserPreferences();

  const [displayLanguageInput, _setDisplayLanguageInput] =
    React.useState<string>();

  const setDisplayLanguageInput = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    _setDisplayLanguageInput(lang);
  };

  const [knownLanguageInput, setKnownLanguageInput] = React.useState<string>();
  const [studyLanguageInput, setStudyLanguage] = React.useState<string>();
  const [defaultScreenInput, setDefaultScreenInput] = React.useState<string>();

  const toast = React.useRef<Toast>();

  React.useEffect(() => {
    initialize();
  }, [preferences]);

  function initialize() {
    if (!preferences) return;

    const { languages, defaultScreen } = preferences;

    const { displayLanguage, knownLanguage, studyLanguage } = languages;

    setDisplayLanguageInput(displayLanguage);
    setKnownLanguageInput(knownLanguage);
    setStudyLanguage(studyLanguage);
    setDefaultScreenInput(defaultScreen);
  }

  function resetChanges() {
    initialize();
    toast.current.show({
      severity: "info",
      summary: t("toasts.summaries.reset_done"),
    });
  }

  async function savePreferences() {
    try {
      await setUserPreferencesState({
        languages: {
          displayLanguage: displayLanguageInput,
          knownLanguage: knownLanguageInput,
          studyLanguage: studyLanguageInput,
        },
        defaultScreen: defaultScreenInput,
      });
      toast.current.show({
        severity: "success",
        summary: t("toasts.summaries.preferences_saved"),
      });
    } catch (error) {
      toast.current.show({
        severity: "success",
        summary: t("toasts.summaries.preferences_not_saved"),
        detail: error.message,
      });
    }
  }

  if (!preferences) return <div>loading...</div>;

  const { languages, defaultScreen } = preferences;

  const { displayLanguage, knownLanguage, studyLanguage } = languages;

  return (
    <>
      <Card
        pt={{
          root: { className: "h-full w-full p-0 m-0" },
          body: { className: "h-full w-full  p-0 m-0" },
          content: {
            className:
              "w-full p-0 m-0 flex flex-wrap flex-column justify-content-center align-items-center",
            style: { height: "calc(100% - 9rem)" },
          },
          title: { className: "w-full flex justify-content-center" },
          subTitle: { className: "w-full flex justify-content-center" },
        }}
        title={t("titles.preferences")}
        subTitle={t("subtitles.change_preferences")}
        footer={
          <PDSettingsFooter
            onClickResetChanges={resetChanges}
            onClickSavePreferences={savePreferences}
          />
        }
      >
        <PDSettingOptionCard
          label={t("settings.known-language")}
          value={knownLanguageInput}
          setValue={setKnownLanguageInput}
          defaultValue={knownLanguage}
          availableValues={LOCALES.map(({ label, value }) => ({
            label: t(label),
            value,
          }))}
        />
        <PDSettingOptionCard
          label={t("settings.study-language")}
          value={studyLanguageInput}
          setValue={setStudyLanguage}
          defaultValue={studyLanguage}
          availableValues={LOCALES.map(({ label, value }) => ({
            label: t(label),
            value,
          }))}
        />

        <PDSettingOptionCard
          label={t("settings.default-screen")}
          value={defaultScreenInput}
          setValue={setDefaultScreenInput}
          defaultValue={defaultScreen}
          availableValues={mainTabDetails.map((tab) => {
            return {
              label: tab,
              value: tab,
            };
          })}
        />

        <PDSettingOptionCard
          label={t("settings.display-language")}
          value={displayLanguageInput}
          setValue={setDisplayLanguageInput}
          defaultValue={displayLanguage}
          availableValues={LOCALES.map(({ label, value }) => ({
            label: t(label),
            value,
          }))}
        />
      </Card>
      <Toast ref={toast} position="bottom-center" />
    </>
  );
}

export default PDSettingsPage;
