import React from "react";

import {
  createUseStyles,
  Button,
  Card,
  Dropdown,
  Toast,
  useUserPreferences,
  setUserPreferencesState,
  LOCALES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const mainTabDetails = ["Reading", "Writing", "Speaking"];

// @ts-ignore
import i18n from "@projectdiction/translations";

const useStyles = createUseStyles({
  root: {
    width: "95%",
    height: "95%",
    "& > .p-card-body": {
      "& > .p-card-title, & > .p-card-subtitle": {
        textAlign: "center",
      },
      "& > .p-card-content": {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  rootCardFooter: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-evenly",
    width: "100%",
  },
  card: {
    // borderRadius: 10,
  },
});

const PDSettingsFooter = ({ onClickSavePreferences, onClickResetChanges }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.rootCardFooter}>
      <Button
        label={t("buttons.save_preferences")}
        icon="pi pi-save"
        className="button-raised"
        onClick={onClickSavePreferences}
      />
      <Button
        label={t("buttons.reset_changes")}
        icon="pi pi-undo"
        className="button-secondary button-raised"
        onClick={onClickResetChanges}
      />
    </div>
  );
};

export default PDSettingsFooter;
