import React from "react";

import {
  Card,
  Dropdown,
  // @ts-ignore
} from "@projectdiction/common-react";

const PDSettingOptionCard = ({
  label,
  defaultValue,
  value,
  setValue,
  availableValues,
}) => {
  return (
    <Card
      title={() => {
        return (
          <div>
            {label}
            {value !== defaultValue && <sup>*</sup>}
          </div>
        );
      }}
      pt={{
        root: { className: "h-10rem w-15rem m-4" },
      }}
    >
      <Dropdown
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        options={availableValues}
      />
    </Card>
  );
};

export default PDSettingOptionCard;
